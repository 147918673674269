import React from "react";
import { LocationProvider } from "@reach/router";
import AccountProvider from "./account";
import CartProvider from "./cart";
import ProductFinderProvider from "./product-finder";

const GlobalProvider = ({ children }) => (
    <AccountProvider>
        <CartProvider>
            <ProductFinderProvider>
                <LocationProvider>
                    {children}            
                </LocationProvider>
            </ProductFinderProvider>
        </CartProvider>
    </AccountProvider>
);

export default GlobalProvider;
